.App {
  font-family: sans-serif;
  text-align: center;
}
h1
{
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
span
{
  font-size: 50px;
  margin: 5px;

}
button
{
  margin:25px;
  width: 100px;
  height: 30px;
  background-color: black;
  color: azure;
  font-size: 17px;
  font-family: Segoe UI', Tahoma, Geneva, Verdana, sans-serif';


}
#min
{
  font-size: 20px;
  color: darkgreen
}
#sec{
  font-size: 20px;
  color: darkgreen;
}

#btns
{
  display: block;
}
#msec
{
  font-size: 20px;
  color: darkgreen;
}